.expire-state-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.expire-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 2;
}

.contact-details {
    position: relative;
    display: inline-block;
    margin-top: 5px;
}

.contact-details:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #000;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.expire-state-wrap span {
    position: relative;
    z-index: 3;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    text-shadow: 0 0 black;
}