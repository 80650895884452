.app-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  width: 80%;
  overflow: hidden;
}
.btn-container {
  padding-top: 20px;
}
.btn {
  padding: 11.6px 37px 11.6px;
  width: 100%;
  border-radius: 4px;
  text-transform: capitalize;
  background-color: #ffd865;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 34px;
}

.btn-cancel {
  padding: 11.6px 37px 11.6px;
  width: 100%;
  border-radius: 4px;
  text-transform: capitalize;
  background-color: #f4f4f7;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 34px;
}

.field-container {
  padding-top: 5px;
}

.reservation-wrap {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  text-shadow: 0 0 black;
  text-align: left;
}

.reservation-field {
  z-index: 3;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  text-shadow: 0 0 black;
}

.FormRowLabel {
  width: 15%;
  min-width: 80px;
  padding: 20px 0px 0px 0px;
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FormRowInput:-webkit-autofill,
.FormRowInput:-webkit-autofill:hover,
.FormRowInput:-webkit-autofill:focus,
.FormRowInput:-webkit-autofill:active {
  -webkit-text-fill-color: #4e4e4e;
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.FormRowInput {
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  padding: 11px 15px 11px 11px;
  color: #434343;
  background-color: #fff;
  border: 1px solid #434343;
  border-radius: 4px;
  text-align: left;
  animation: 1ms void-animation-out;
}

.FormRowInput:focus {
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  padding: 11px 15px 11px 11px;
  color: #000;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #434343;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: #b6b6b6;
}
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  color: red;
}
select {
  /* Reset Select */
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: #fff;
  background-color: #424242;
  background-image: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  background-color: #424242;
}
.select::focus {
  border-radius: 4px 4px 0px 0px;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #424242;
  color: #dcdcdc;
  transition: 0.25s all ease;
  pointer-events: none;
}
/* Transition */
.select:hover::after {
  color: #fff;
}

.title-reservation {
  color: #000;
  font-size: 21px;
  padding-bottom: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
