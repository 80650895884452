body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* global styling */


/* Eric Meyer's Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: #000;
}

ol,
ul {
    list-style: none;
}


/* Font Face */


/* Global Typography */

*,
 :before,
 :after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 100%;
}


/* Global Headings */

h1 {}

h2 {}


/* General Text Formatting */

p {}

small {
    font-size: 85.71429%;
    line-height: 1.75;
    display: inline-block;
}

img {
    max-width: 100%;
    height: auto;
}


/* Global Link style */

a {
    color: #000;
    text-decoration: none;
    outline: none;
    display: inline-block;
}


/* Global Button Styles */

button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}


/* Global style */

.container {
    max-width: 1183px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}