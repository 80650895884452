/* custom css */

.slider-wrapper-custom {
  position: fixed;
  bottom: 20px;
  width: 288px;
  height: 65px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.slider-wrapper-custom:after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  height: 15px;
  width: 15px;
  background-color: #ffffff;
}

.carousel.carousel-slider {
  width: 100%;
  height: 65px;
}

.slider-wrapper-custom .carousel.carousel-slider .control-arrow {
  opacity: 0;
}

.slide-text {
  color: #000000;
  font-weight: 600;
  font-family: Poppins;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-status {
  display: none;
}

.slider-wrapper-custom .carousel .control-dots .dot {
  height: 5px;
  width: 5px;
  background-color: #d2d5db;
  opacity: 1;
  box-shadow: none;
  margin: 0 2px;
}

.slider-wrapper-custom .carousel .control-dots .dot.selected,
.slider-wrapper-custom .carousel .control-dots .dot:hover {
  background-color: #625f5f;
}

.slider-wrapper-custom .carousel .control-dots {
  bottom: auto;
  top: 0;
  margin: 2px 0;
}

.content-text {
  position: absolute;
  top: -48px;
  left: 0;
  text-align: left;
  color: #e7e7e7;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.slider-wrapper-custom .control-arrow {
  opacity: 0;
}
