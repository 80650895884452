.share-icon {
    height: 17.82px;
    width: 15px;
    display: inline-block;
    margin-left: 5px;
    margin-top: -2px;
}

.share-icon svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: #007AFF;
}