.logo-wrapper {
    margin-bottom: 8%;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-img svg g {
    fill: #fff;
}